@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@700&display=swap');

.App {
  height:max-content;
  text-align: center;  
  background: #B9CFDA;
  background: -webkit-linear-gradient(top, #B9CFDA, #FFFFFF);
  background: -moz-linear-gradient(top, #B9CFDA, #FFFFFF);
  background: linear-gradient(to bottom, #B9CFDA, #FFFFFF);

}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.logo {
  height: 1.2in;
  margin-top: 2%;
}

@media screen and (max-width:700px){
  .logo{
    height: 1in;
    margin-top: 2%;
  }
}

.App-link {
  color: #61dafb;
}

.player {
  display:flex;
  text-align: left;
  margin-left: 30px;
  margin-top: 50px;
}

.title {
  font-family: 'Mulish', sans-serif;
  font-weight: 700;
}
