.footer {
    flex:1;
    flex-direction: column;
    margin-top: 10%;
  }

@media screen and (max-width:700px) {
    .footer{
        flex:1;
        flex-direction: column;
        margin-top: 35%;
    }
}

a:link, a:visited {
    color:black;
    cursor:alias;
    text-decoration: none;
}

